import React, { useMemo, useState } from 'react';
import { Box, Typography, Chip, Drawer, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useTheme } from '@mui/material/styles';
import DetailedAsset from '../Asset/DetailedAssset';

const RiskTable = ({ risks, onRiskRowClick }) => {
  const theme = useTheme();
  const [selectedResource, setSelectedResource] = useState(null);

  const statusColors = {
    open: theme.palette.error.main,
    closed: theme.palette.success.main,
    ignored: theme.palette.warning.main,
  };

  const indexColors = [
    theme.palette.riskLevels.veryLow, // 0: Very Low
    theme.palette.riskLevels.low, // 1: Low
    theme.palette.riskLevels.medium, // 2: Medium
    theme.palette.riskLevels.high, // 3: High
    theme.palette.riskLevels.veryHigh, // 4: Very High
  ];

  const circleStyle = {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const renderCircleChip = (data, fallbackLabel = 'Not Specified') => {
    const value = data?.[0]?.value || fallbackLabel;
    const index = data?.[0]?.index;
    const color =
      index !== null && index >= 0 && index < indexColors.length
        ? indexColors[index]
        : theme.palette.grey[500];

    return (
      <Chip
        icon={<div style={{ ...circleStyle, backgroundColor: color }} />}
        label={value}
      />
    );
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'threat_source_type',
        header: 'Threat Source Type',
        size: 150,
      },
      {
        accessorKey: 'threat_source',
        header: 'Threat Source',
        size: 200,
      },
      {
        accessorKey: 'attack_scenario',
        header: 'Attack Scenario',
        size: 300,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()} arrow>
            <span>{truncateText(cell.getValue(), 50)}</span>
          </Tooltip>
        ),
      },
      {
        accessorKey: 'target_object',
        header: 'Target',
        size: 200,
        Cell: ({ cell }) => {
          const resource = cell.getValue();
          if (!resource) return 'Not Specified';

          return (
            <Chip
              label={resource.title}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering row click
                setSelectedResource(resource);
              }}
              sx={{ cursor: 'pointer' }}
            />
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
          const color = statusColors[value] || theme.palette.grey[500];
          return (
            <Chip
              icon={<div style={{ ...circleStyle, backgroundColor: color }} />}
              label={cell.getValue()}
            />
          );
        },
      },
      {
        accessorKey: 'responses',
        header: 'Response',
        size: 200,
        Cell: ({ cell }) => {
          const response = cell.getValue();
          if (Array.isArray(response) && response.length > 0) {
            return response[0]?.risk_response || 'Not Specified';
          }
          return 'Not Specified';
        },
      },
      {
        accessorKey: 'risk',
        header: 'Risk',
        size: 100,
        Cell: ({ cell }) => renderCircleChip(cell.getValue()),
      },
      {
        accessorKey: 'residual_risk',
        header: 'Residual Risk',
        size: 100,
        Cell: ({ cell }) => renderCircleChip(cell.getValue()),
      },
    ],
    [statusColors, indexColors, theme.palette.grey]
  );
  console.log(selectedResource)
  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        Risks
      </Typography>
      <MaterialReactTable
        columns={columns}
        data={risks || []}
        enableColumnOrdering
        enableStickyHeader
        muiTableBodyRowProps={({ row }) => ({
          onClick: (e) => {
            if (e.target.tagName !== 'BUTTON') {
              onRiskRowClick(row.original);
            }
          },
          sx: {
            cursor: 'pointer',
          },
        })}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: 1,
            borderColor: 'grey.200',
          },
        }}
      />

      <Drawer
        anchor="right"
        open={Boolean(selectedResource)}
        onClose={() => setSelectedResource(null)}
        PaperProps={{ sx: { width: '80%', padding: 2, marginTop: '64px' } }}
      >
        {selectedResource && (
          <DetailedAsset id={selectedResource.id} type={selectedResource.type} />
        )}
      </Drawer>
    </Box>
  );
};

export default RiskTable;

