import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
  LinearProgress,
  Box,
  Typography,
  Paper,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyValueFilter from '../General/KeyValueFilter'; // Assuming you have this component for tag filtering

// Styled LinearProgress with custom color for the background
const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiLinearProgress-barColorSuccess': {
    backgroundColor: theme.palette.success.main, // Color of the progress bar
  },
  '& .MuiLinearProgress-dashed': {
    backgroundColor: 'transparent', // Removes the dashed background
  },
  '& .MuiLinearProgress-bar1Determinate': {
    backgroundColor: theme.palette.success.main, // Keeps determinate bar the same as success color
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: theme.palette.grey, // Background color of the entire progress component
  },
}));

const ComplianceTable = ({ data, loading }) => {
  // State for tag filtering
  const [tagFilters, setTagFilters] = useState([]);
  const [filterOperators, setFilterOperators] = useState([]); // AND/OR between filters

  // Columns setup
  const columns = [
    { accessorKey: 'information_system', header: 'Information System' },
    { accessorKey: 'standard', header: 'Standard' },
    { accessorKey: 'control', header: 'Control' },
    { accessorKey: 'subControl', header: 'SubControl' },
    { accessorKey: 'type', header: 'Type' }, // e.g., MANUAL/AUTOMATIC
    {
      accessorKey: 'compliance_status',
      header: 'Compliance Status',
      AggregatedCell: ({ row }) => {
        // Use row.subRows to calculate percentages only within this group
        const allStatuses = row.leafRows.map(
          (subRow) => subRow.original.compliance_status
        );
        const okCount = allStatuses.filter((status) => status === 'OK').length;
        const nokCount = allStatuses.filter((status) => status === 'NOK').length;
        const naCount = allStatuses.filter(
          (status) => status === 'Not Assessed'
        ).length;
        const totalCount = okCount + nokCount + naCount;
        const okPercentage = totalCount > 0 ? (okCount / totalCount) * 100 : 0;

        return (
          <Box sx={{ width: '100%', mr: 1 }}>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <CustomLinearProgress variant="determinate" value={okPercentage} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                  {`${okPercentage.toFixed(2)}%`}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      AggregatedCell: ({ row }) => {
        // Collect all status values from subRows
        const allStatuses = row.leafRows.map((subRow) => subRow.original.status);
        // Count how many 'Done' statuses there are
        const doneCount = allStatuses.filter((status) => status === 'Done')
          .length;
        return `${doneCount} of ${allStatuses.length} Done`;
      },
    },
    {
      accessorKey: 'tags',
      header: 'Tags',
      Cell: ({ cell }) => {
        const tags = cell.getValue(); // Assuming `tags` is an array of key-value pairs
        return (
          <Box>
            {tags?.map((tag, index) => (
              <Tooltip
                key={index}
                title={`${tag.key}: ${tag.value}`}
                arrow
              >
                <span
                  style={{
                    marginRight: '8px',
                    backgroundColor: '#f0f0f0',
                    padding: '4px',
                    borderRadius: '4px',
                  }}
                >
                  {tag.key}: {tag.value}
                </span>
              </Tooltip>
            ))}
          </Box>
        );
      },
    },
  ];

  // Filtered data based on tag filters
  const filteredData = useMemo(() => {
    const activeFilters = tagFilters.filter(({ key, value }) => key && value);
    if (activeFilters.length === 0) {
      return data; // No filters applied, return all data
    }

    return data.filter((row) => {
      let result = null;
      activeFilters.forEach(({ key, value }, index) => {
        if (!key || !value) return;

        const matches = row.tags?.some(
          (tag) =>
            tag.key === key && tag.value.toLowerCase().includes(value.toLowerCase())
        );

        if (result === null) {
          result = matches;
        } else if (filterOperators[index - 1] === 'AND') {
          result = result && matches;
        } else if (filterOperators[index - 1] === 'OR') {
          result = result || matches;
        }
      });
      return result;
    });
  }, [data, tagFilters, filterOperators]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 1000,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          maxHeight: 1000,
          overflow: 'auto',
          border: 1,
          borderColor: 'grey.200',
        }}
      >
        {/* Sticky container for the tag filter at the top */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.paper,
            zIndex: 2,
            borderBottom: 1,
            borderColor: 'grey.300',
            p: 2,
          }}
        >
          <KeyValueFilter
            tagFilters={tagFilters}
            filterOperators={filterOperators}
            uniqueTagKeys={data.reduce((acc, row) => {
              row.tags?.forEach((tag) => {
                if (!acc.includes(tag.key)) {
                  acc.push(tag.key);
                }
              });
              return acc;
            }, [])}
            handleTagFilterChange={(index, field, value) => {
              const newFilters = tagFilters.map((filter, i) =>
                i === index ? { ...filter, [field]: value } : filter
              );
              setTagFilters(newFilters);
            }}
            handleOperatorChange={(index, value) => {
              const newOperators = [...filterOperators];
              newOperators[index] = value;
              setFilterOperators(newOperators);
            }}
            handleAddTagFilter={() => {
              setTagFilters([...tagFilters, { key: '', value: '' }]);
              setFilterOperators([...filterOperators, 'AND']);
            }}
            handleRemoveTagFilter={(index) => {
              const newFilters = tagFilters.filter((_, i) => i !== index);
              const newOperators = filterOperators.filter((_, i) => i !== index);
              setTagFilters(newFilters);
              setFilterOperators(newOperators);
            }}
          />
        </Box>

        {/* MaterialReactTable below the sticky filter */}
        <MaterialReactTable
          columns={columns}
          data={filteredData} // Use filteredData instead of raw data
          muiTablePaperProps={{
            elevation: 0, // Remove the default shadow
            sx: {
              borderRadius: 0,
              border: 0, // We'll rely on the parent Paper for the border
            },
          }}
          initialState={{
            density: 'compact',
            columnVisibility: { myColumnId: false },
            grouping: ['information_system'],
            pagination: { pageSize: 25 }, // Set initial page size to 25
          }}
          enableColumnOrdering
          enableColumnResizing
          enableSorting
          enableGrouping
          state={{ isLoading: loading }}
        />
      </Paper>
    </Box>
  );
};

export default ComplianceTable;
