import React, { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  Panel,
  useNodesState,
  useEdgesState,
  MarkerType,
} from 'reactflow';
import Dagre from '@dagrejs/dagre';
import { AppBar, Toolbar, IconButton, Typography, Button, Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CustomNode from '../General/EunoiaFlow/CustomNode';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FlagIcon from '@mui/icons-material/Flag';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';
import PestControlIcon from '@mui/icons-material/PestControl';
import ThreatSourceNode from '../General/EunoiaFlow/ThreatSourceNode';


// Dagre graph configuration for layout
const dagreGraph = new Dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const SupportingAssetNode = (props) => (
    <CustomNode {...props} icon={StorageIcon} iconColor="#F5F5F5" backgroundColor="#4a148c
    " />
  )
  const PrimaryAssetNode = (props) => (
    <CustomNode {...props} icon={DocumentScannerIcon} iconColor="#F5F5F5" backgroundColor="#e65100" />
  )
  
  const ISNode = (props) => (
    <CustomNode {...props} icon={DeviceHubIcon} iconColor="#F5F5F5" backgroundColor="#006064" />
  )
  const MissionNode = (props) => (
    <CustomNode {...props} icon={FlagIcon} iconColor="#F5F5F5" backgroundColor="#1b5e20" />
  )
  
  const OrganisationNode = (props) => (
    <CustomNode {...props} icon={CorporateFareIcon} iconColor="#F5F5F5" backgroundColor="#4a148c" />
  )
  
  const ThreatNode = (props) => (
    <CustomNode {...props} icon={WarningIcon} iconColor="#F5F5F5" backgroundColor="#b71c1c" />
  )
  
  const DefaultNode = (props) => (
    <CustomNode {...props} icon={HelpOutlineIcon} iconColor="#F5F5F5" backgroundColor="#9E9E9E" />
  )
  
  const StakeholderNode = (props) => (
    <CustomNode {...props} icon={GroupIcon} iconColor="#F5F5F5" backgroundColor="#607d8b" />
  )
  
  const VulnerabilityNode = (props) => (
    <CustomNode {...props} icon={PestControlIcon} iconColor="#FFF" backgroundColor="#FF9800" />
  )



  const getLayoutedElements = (nodes, edges, direction = 'TB') => {
    // If no nodes, return early
    if (!nodes.length) {
      return { nodes: [], edges: [] };
    }
  
    // Increase node dimensions for better label spacing
    const nodeWidth = 200; 
    const nodeHeight = 50; 
  
    // Increase spacing between nodes/ranks to reduce collisions
    const ranksep = 150; 
    const nodesep = 80;  
    const edgesep = 30;  
  
    dagreGraph.setGraph({
      rankdir: direction,  // 'TB' or 'LR'—make sure it matches how you call getLayoutedElements(...)
      ranksep,
      nodesep,
      edgesep,
      marginx: 20,
      marginy: 20,
    });
  
    // Add nodes to Dagre
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  
    // Add edges to Dagre, specifying a minlen for extra spacing
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target, {
        minlen: 2,  // Increase to 3 or more if still overlapping
      });
    });
  
    // Perform the Dagre layout
    Dagre.layout(dagreGraph);
  
    // Apply the Dagre-computed positions (centered in each cell)
    nodes.forEach((node) => {
      const { x, y } = dagreGraph.node(node.id);
      node.position = {
        x: x - nodeWidth / 2,
        y: y - nodeHeight / 2,
      };
    });
  
    // -----------------------------------------------
    // ONLY override the Y position by "level"
    // -----------------------------------------------
    const levels = {
      mission: 0,
      organization: 1,
      primary_assets: 2,
      supporting_assets: 2,
      stakeholder: 2,
      information_system: 2,
      vulnerability: 3,
      threat: 4,
      threatSource: 4,
    };
  
    // Vertical distance between levels
    const levelHeight = 200;
  
    nodes.forEach((node) => {
      const level = levels[node.type];
      if (typeof level !== 'undefined') {
        // Keep Dagre's X to avoid horizontal collisions, 
        // but snap Y to the defined "level"
        node.position.y = level * levelHeight;
      }
    });
  
    return { nodes, edges };
  };
  


const AttackScenarioViewer = ({ data }) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [isFullScreen, setIsFullScreen] = useState(false); // State to manage full-screen view


    // Memoize nodeTypes to avoid re-creation on every render
    const nodeTypes = useMemo(() => ({
        supporting_assets: SupportingAssetNode,
        primary_assets: PrimaryAssetNode,
        information_system: ISNode,
        mission: MissionNode,
        organization: OrganisationNode,
        threat: ThreatNode,
        stakeholder: StakeholderNode,
        vulnerability: VulnerabilityNode,
        threatSource: ThreatSourceNode,
        undefined: DefaultNode
      }), [])

  useEffect(() => {
    const scenarioNodes = [];
    const scenarioEdges = [];
    const addedNodeIds = new Set();

    // Add source threat node
    if (data.threat_source) {
        const sourceNodeId = 'source-threat';
        if (!addedNodeIds.has(sourceNodeId)) {
          addedNodeIds.add(sourceNodeId);
          
          const isAdversarial = data.threat_source_type === 'adversarial'; // Example: Check the type
          scenarioNodes.push({
            id: 'source-threat',
            type: 'threatSource', // Matches the key in nodeTypes
            data: {
              label: data.threat_source,
              description: isAdversarial ? 'Adversarial Threat Source' : 'Non-Adversarial Threat Source',
              iconColor: isAdversarial ? '#ff4d4d' : '#4caf50',
              backgroundColor: isAdversarial ? '#ffe6e6' : '#e8f5e9',
            },
            position: { x: 0, y: 0 },
          });
          
        }
      }

    // Add attack steps
    const usedTargets = new Set();
    (data.attack_steps || []).forEach((step, index) => {
      const stepNodeId = `step-${step.id}`;
      if (!addedNodeIds.has(stepNodeId)) {
        addedNodeIds.add(stepNodeId);
        scenarioNodes.push({
          id: stepNodeId,
          type: 'threat',
          data: {
            label: step.title,
            description: step.description,
            icon: DirectionsRunIcon, // Pass the icon component directly
            iconColor: '#1976d2',
            backgroundColor: '#e3f2fd',
          },
          position: { x: 0, y: 0 },
        });
      }

      // Add edges between steps
      const sourceNodeId = index === 0 ? 'source-threat' : `step-${data.attack_steps[index - 1].id}`;
      if (index === 0) {
        // Specific edge between threat source and first threat
        scenarioEdges.push({
          id: `edge-${sourceNodeId}-${stepNodeId}`,
          source: sourceNodeId,
          target: stepNodeId,
          sourceHandle: 'source-right', // Connect from the right handle
          targetHandle: 'target-left',
          animated: true,
          label: 'Initiates',
          style: { stroke: '#ffa500' }, // Different color for specific edge
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 50,
            height: 20,
            color: '#ffa500', // Orange for specific edge
          },
        });
      } else {
        // Regular edges between other steps
        scenarioEdges.push({
          id: `edge-${sourceNodeId}-${stepNodeId}`,
          source: sourceNodeId,
          target: stepNodeId,
          animated: true,
          label: 'Next Step',
          style: { stroke: 'red' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 50,
            height: 20,
            color: 'red',
          },
        });
      }


      // Add target connection
      if (step.target) {
        usedTargets.add(step.target);
        scenarioEdges.push({
          id: `edge-${stepNodeId}-${step.target}`,
          source: stepNodeId,
          sourceHandle: 'source-attack', // Assuming 'attack' is a valid source handle in your node
          targetHandle: 'target-attack',
          target: step.target,
          animated: true,
          label: 'Targets',
          style: { stroke: 'red' }, // Set the edge color to red
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 50,
            height: 20,
            color: 'red'
          }
        });
      }

      // Add vulnerabilities
      (step.vuln_edges || []).forEach((vulnId) => {
        const vuln = data.all_vulnerabilities?.find((v) => v.vuln_edge === vulnId);
        const vulnNodeId = `vuln-${vulnId}`;
        if (vuln && !addedNodeIds.has(vulnNodeId)) {
          addedNodeIds.add(vulnNodeId);
          scenarioNodes.push({
            id: vulnNodeId,
            type: 'vulnerability',
            data: {
              label: vuln.title,
              description: vuln.description,
              icon: BugReportIcon, // Pass the icon component directly
              iconColor: '#ff9800',
              backgroundColor: '#fff3e0',
            },
            position: { x: 0, y: 0 },
          });

          // Edge from step to vulnerability
          scenarioEdges.push({
            id: `edge-${stepNodeId}-${vulnNodeId}`,
            source: stepNodeId,
            target: vulnNodeId,
            animated: true,
            label: 'Exploits',
          });
        }
      });
    });

    // Add target nodes
    (data.all_targets || []).forEach((target) => {
      if (usedTargets.has(target.node_id) && !addedNodeIds.has(target.node_id)) {
        addedNodeIds.add(target.node_id);
        scenarioNodes.push({
          id: target.node_id,
          type: target.type,
          data: {
            label: target.title,
            description: target.description,
            icon: SecurityIcon, // Pass the icon component directly
            iconColor: '#4caf50',
            backgroundColor: '#e8f5e9',
          },
          position: { x: 0, y: 0 },
        });
      }
    });
    // Layout the nodes and edges
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      scenarioNodes,
      scenarioEdges,
      'LR'
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [data]);

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };
  return (
        <>
          <div style={{ width: '100%', height: '600px' }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              fitView
            >
              <Background />
              <Controls />
              <Panel position="top-right">
            <button onClick={() => toggleFullScreen()}>Full screen</button>
          </Panel>
            </ReactFlow>
          </div>
    
          {/* Full-Screen Dialog */}
          <Dialog
            open={isFullScreen}
            onClose={toggleFullScreen}
            maxWidth="lg"
            fullWidth
            PaperProps={{
              style: {
                height: '95%',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
              },
            }}
            BackdropProps={{
              style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={toggleFullScreen} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ flex: 1 }} variant="h6">
                  Full-Screen Graph
                </Typography>
                <Button autoFocus color="inherit" onClick={toggleFullScreen}>
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                fitView
              >
                <Background />
                <Controls />
              </ReactFlow>
            </DialogContent>
          </Dialog>
        </>
      );
    };
    

export default AttackScenarioViewer;
