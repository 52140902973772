import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

const ThreatSourceNode = ({ data }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div
      className="threat-source-node"
      style={{
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
      }}
      onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on hover
      onMouseLeave={() => setTooltipVisible(false)} // Hide tooltip on leave
    >
      {/* Tooltip */}
      {isTooltipVisible && (
        <div
          style={{
            position: 'absolute',
            top: '-35px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#333',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            zIndex: 10,
          }}
        >
          {data.description}
        </div>
      )}

      {/* Icon */}
      <FontAwesomeIcon
        icon={faUserSecret}
        style={{
          fontSize: '32px',
          color: data.iconColor || '#ff4d4d',
        }}
      />

      {/* Label */}
      <div
        style={{
          marginTop: '8px',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        {data.label}
      </div>

      {/* Handle for connections */}
      <Handle
        type="source"
        position={Position.Right}
        id="source-right"
        style={{ backgroundColor: '#b71c1c' }}
      />
    </div>
  );
};

export default ThreatSourceNode;
