import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Divider,
  Tabs,
  Tab,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Chip,
  useTheme,
  Drawer,
  CircularProgress,
} from '@mui/material';

// Icons
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import SecurityIcon from '@mui/icons-material/Security'; // Example for 'Compliance' tab
import WarningIcon from '@mui/icons-material/Warning';   // Example for 'Risks' tab

import { MaterialReactTable } from 'material-react-table';

import useRequestCompliance from 'src/hooks/useRequestCompliance';
import CategorizationComponent from 'src/components/Asset/CategorizationComponent';
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector';
import ComplianceTable from '../complianceAssessment/ComplianceTable';
import VulnerabilityTable from '../Risk/VulnerabilityTable';

// Keep your circle style if needed:
const circleStyle = {
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

// --- Sub-components for the tab panels ---

const OverviewTabContent = ({ assetDetails, catInstance, onEditLevels }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
        Overview
      </Typography>

      <Grid container spacing={2}>
        {/* Basic Info */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Created By"
                    secondary={assetDetails?.created_by || 'N/A'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Created At"
                    secondary={
                      assetDetails?.created_at
                        ? assetDetails.created_at
                        : 'N/A'
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Updated At"
                    secondary={
                      assetDetails?.updated_at
                        ?  assetDetails.updated_at
                        : 'N/A'
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Roles and Responsibilities */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Roles and Responsibilities
              </Typography>
              {assetDetails?.roles && Object.keys(assetDetails.roles).length > 0 ? (
                <List>
                  {Object.entries(assetDetails.roles).map(([roleTitle, userDetails], index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={roleTitle}
                        secondary={
                          userDetails
                            ? `${userDetails.username} (${userDetails.email})`
                            : 'Unassigned'
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2">No roles assigned.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Categorization Component */}
        {catInstance && (
          <Grid item xs={12}>
            <CategorizationComponent
              key={catInstance.id}
              data={catInstance}
              editLevels={onEditLevels}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const VulnerabilitiesTabContent = ({ vulns }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
        Vulnerabilities
      </Typography>
      <VulnerabilityTable vulns={vulns?.results || []} />
    </Box>
  );
};

const ComplianceTabContent = ({ data, loading }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
        Compliance
      </Typography>
      {loading ? <CircularProgress /> : <ComplianceTable data={data || []} loading={loading} />}
    </Box>
  );
};

const RisksTabContent = ({ risks }) => {
  const theme = useTheme();

  const statusColors = {
    open: theme.palette.error.main,
    closed: theme.palette.success.main,
    ignored: theme.palette.warning.main,
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'threat_source',
        header: 'Threat Source',
        size: 200,
      },
      {
        accessorKey: 'threat_source_type',
        header: 'Threat Source Type',
        size: 150,
      },
      {
        accessorKey: 'objective',
        header: 'Objective',
        size: 200,
      },
      {
        accessorKey: 'associated_feared_event',
        header: 'Associated Feared Event',
        size: 200,
      },
      {
        accessorKey: 'attack_scenario',
        header: 'Attack Scenario',
        size: 300,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
          const color = statusColors[value] || theme.palette.grey[500];
          return (
            <Chip
              label={cell.getValue()}
              sx={{
                backgroundColor: color,
                color: theme.palette.getContrastText(color),
              }}
            />
          );
        },
      },
      {
        accessorKey: 'risk.Risk',
        header: 'Risk',
        size: 150,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue() || 'Not Specified'} arrow>
            <span>{cell.getValue() || 'Not Specified'}</span>
          </Tooltip>
        ),
      },
    ],
    [statusColors, theme.palette.grey],
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
        Risks
      </Typography>
      <MaterialReactTable
        columns={columns}
        data={risks?.results || []}
        enableColumnOrdering
        enableStickyHeader
        muiTableContainerProps={{
          sx: { maxHeight: '1000px' },
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: 1,
            borderColor: 'grey.200',
          },
        }}
      />
    </Box>
  );
};

// --- Main DetailedAsset component ---

const DetailedAsset = ({ id, type }) => {
  const [catId, setCatId] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Data hooks
  const { getCompliance, compliance: assetDetails } = useRequestCompliance({ endpoint: type });
  const {
    getCompliance: getCatInstance,
    compliance: catInstance,
    resetCompliance: resetCatInstance,
  } = useRequestCompliance({ endpoint: 'categorization_instances' });
  const { getComplianceList: getVulns, complianceList: vulns } = useRequestCompliance({
    endpoint: 'vulnerabilityinstances',
  });
  const { getComplianceList: getRisks, complianceList: risks } = useRequestCompliance({
    endpoint: 'risk_instances',
  });
  const {
    getComplianceList: getInstances,
    complianceList: scInstances,
    loading: loadingInstance,
  } = useRequestCompliance({
    endpoint: 'subcontrolinstancemetric',
    resourceLabel: 'Subcontrol Instance metrics',
  });

  // Fetch data on mount/update
  useEffect(() => {
    if (id) {
      getCompliance(id);
      if (type === 'information_systems' || type === 'informationsystems') {
        getVulns({ information_system: id });
        getRisks({ information_systems: id });
        getInstances({ information_systems: id });
      } else if (type === 'supporting_assets' || type === 'supportingassets') {
        getVulns({ supporting_asset: id });
        getRisks({ supporting_assets: id });
        getInstances({ supporting_assets: id });
      } else if (type === 'primary_assets' || type === 'primaryassets') {
        getVulns({ primary_asset: id });
        getRisks({ primary_assets: id });
        getInstances({ primary_assets: id });
      } else if (type === 'organizations') {
        getVulns({ organization: id });
        getRisks({ organization: id });
        getInstances({ organization: id });
      } else if (type === 'missions') {
        getVulns({ mission: id });
        getRisks({ missions: id });
        getInstances({ missions: id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  // Determine what categorization_id to use
  useEffect(() => {
    if (assetDetails) {
      if (assetDetails.security_requirements) {
        setCatId(assetDetails.security_requirements);
      } else if (assetDetails.security_compatibilities) {
        setCatId(assetDetails.security_compatibilities);
      } else {
        setCatId(null);
      }
    } else {
      setCatId(null);
    }
  }, [assetDetails]);

  // Fetch or reset categorization instance
  useEffect(() => {
    if (catId) {
      getCatInstance(catId);
    } else {
      resetCatInstance(); // reset if no catId
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catId]);

  // Handle Tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // For opening/closing the side drawer that edits levels
  const handleEditLevels = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  // Render content for each tab
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <OverviewTabContent
            assetDetails={assetDetails || {}}
            catInstance={catInstance}
            onEditLevels={handleEditLevels}
          />
        );
      case 1:
        return <VulnerabilitiesTabContent vulns={vulns} />;
      case 2:
        return <ComplianceTabContent data={scInstances?.results?.flat()} loading={loadingInstance} />;
      case 3:
        return <RisksTabContent risks={risks} />;
      default:
        return null;
    }
  };

  // If no assetDetails yet, show a loading or fallback
  if (!assetDetails) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading Asset Details...</Typography>
      </Box>
    );
  }

  // Main return
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* Header section (Title, etc.) */}
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {assetDetails.title || 'Asset Detail'}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {assetDetails.description || ''}
        </Typography>
      </Box>

      <Divider />
      
      {/* Main content area: Vertical Tabs + Tab Content */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Left side: Vertical Tabs */}
        <Box sx={{ borderRight: 1, borderColor: 'divider', width: 240 }}>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            variant="standard"
            sx={{
              width: '100%',
              '.MuiTabs-flexContainer': {
                alignItems: 'flex-start',
              },
              // This ensures the icon and label in each Tab are aligned horizontally
              '.MuiTab-root': {
                justifyContent: 'flex-start',
                textAlign: 'left',
                paddingLeft: 2,
              },
              '.MuiTab-wrapper': {
                flexDirection: 'row',
                gap: '8px',
                justifyContent: 'flex-start',
              },
            }}
          >
            <Tab icon={<InfoIcon />} iconPosition="start" label="Overview" />
            <Tab icon={<BugReportIcon />} iconPosition="start" label="Vulnerabilities" />
            <Tab icon={<SecurityIcon />} iconPosition="start" label="Compliance" />
            <Tab icon={<WarningIcon />} iconPosition="start" label="Risks" />
          </Tabs>
        </Box>

        {/* Right side: Tab Content */}
        <Box sx={{ flex: 1, overflowY: 'auto' }}>{renderTabContent()}</Box>
      </Box>

      <Divider />

      {/* Drawer for editing categorization levels */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '40%',
            marginTop: '64px',
          },
        }}
      >
        <Box p={2} sx={{ flexGrow: 1 }}>
          {catInstance && (
            <DynamicLevelSelector
              data={catInstance.categorization_model}
              initialValues={catInstance}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default DetailedAsset;
