import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Tabs, Tab, Chip, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import { useTheme } from '@mui/material/styles';
import { Warning, BugReport, Info, Timeline,ArrowForward } from '@mui/icons-material';
import RouteIcon from '@mui/icons-material/Route';
import GroupIcon from '@mui/icons-material/Group';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty'; // Correct import for "Not Started"
import Autorenew from '@mui/icons-material/Autorenew'; // Correct import for "In Progress"
import CheckCircle from '@mui/icons-material/CheckCircle'; // Correct import for "Completed"
import PauseCircle from '@mui/icons-material/PauseCircle'; // Correct import for "On Hold"
import Cancel from '@mui/icons-material/Cancel'; // Correct import for "Cancelled"
import VulnerabilityTable from './VulnerabilityTable';
import SecurityMeasuresTable from './SecurityMeasuresTable';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import ReadOnlyAttackScenario from './ReadOnlyAttackScenario';
import ImpactInstances from './ImpactInstances';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExplosion } from '@fortawesome/free-solid-svg-icons';
import ReplyIcon from '@mui/icons-material/Reply';
const circleStyle = {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };




const RiskDetail = ({ risk }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { getResourceAttribute:getDelaitedRisks, complianceList: detailedRisks } = useRequestCompliance({ endpoint: 'risk_instances', resourceLabel: 'Risks' });
  const theme = useTheme();

  useEffect(()=>{
    getDelaitedRisks(risk?.id,'details')
  },[])
  const responseColors = {
    mitigate: theme.palette.info.light, // Blue
    accept: theme.palette.riskLevels.veryHigh, // Green
    avoid: theme.palette.warning.light, // Yellow
    transfer: theme.palette.error.light, // Red
    unspecified: theme.palette.grey[500], // Grey
  };
  const indexColors = [
    theme.palette.riskLevels.veryLow, // 0: Very Low
    theme.palette.riskLevels.low, // 1: Low
    theme.palette.riskLevels.medium, // 2: Medium
    theme.palette.riskLevels.high, // 3: High
    theme.palette.riskLevels.veryHigh, // 4: Very High
  ];
  console.log(detailedRisks)

  if (!risk) {
    return (
      <Typography variant="body2">
        No risk selected.
      </Typography>
    );
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
        case 0: // Overview
        const severityCounts = detailedRisks?.all_vulnerabilities?.reduce(
          (acc, vuln) => {
            const severity = vuln.severity || 'Unknown';
            acc[severity] = (acc[severity] || 0) + 1;
            return acc;
          },
          {}
        );
  
        const getMetricChip = (metric, label) => {
          const index = metric?.[0]?.index;
          const value = metric?.[0]?.value || 'N/A';
          const color =
            index !== null && index >= 0 && index < indexColors.length
              ? indexColors[index]
              : theme.palette.grey[500];
  
          return (
            <Chip
              icon={
                <div
                  style={{
                    ...circleStyle,
                    backgroundColor: color,
                  }}
                />
              }
              label={`${label} ${value}`}
            />
          );
        };
  
        return (
          <Box sx={{ p: 3, 
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            minWidth: '800px',
             }} >
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
              Overview
            </Typography>
            <Grid container spacing={3}>
              {/* Status and Timestamps */}
              <Grid item xs={12} md={6}>
  <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 ,      overflow: 'hidden', // Prevent content overflow
}}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
      <Info color="primary" />
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
      Status Overview
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* Status */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '120px' }}>
          Status:
        </Typography>
        <Chip
          icon={
            <div
              style={{
                ...circleStyle,
                backgroundColor:
                  risk.status?.toLowerCase() === 'closed'
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            />
          }
          label={risk.status || 'N/A'}
        />
      </Box>
      {/* Risk Response Type */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
  <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '120px' }}>
    Response Type:
  </Typography>
  <Chip
    icon={
      <Box
        sx={{
          ...circleStyle,
          backgroundColor: responseColors[
            risk?.responses?.[0]?.risk_response || 'unspecified'
          ],
        }}
      />
    }
    label={
      risk?.responses?.[0]?.risk_response
        ? risk.responses[0].risk_response.charAt(0).toUpperCase() + risk.responses[0].risk_response.slice(1)
        : 'Not Specified'
    }
    sx={{
      backgroundColor: theme.palette.grey[200], // Grey background for chip
      color: theme.palette.text.primary, // Text color
    }}
  />
</Box>
      {/* Created At */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '120px' }}>
          Created At:
        </Typography>
        <Typography variant="body2">{detailedRisks.created_at || 'N/A'}</Typography>
      </Box>

      {/* Updated At */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '120px' }}>
          Updated At:
        </Typography>
        <Typography variant="body2">{detailedRisks.updated_at || 'N/A'}</Typography>
      </Box>
    </Box>
  </Box>
</Grid>

              {/* Roles */}
            {/* Roles */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 2
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <GroupIcon color="info" />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Roles
                  </Typography>
                </Box>

                {Object.entries(detailedRisks.roles || {}).length > 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {Object.entries(detailedRisks.roles).map(([role, user]) => (
                      <Typography key={role} variant="body2">
                        <span style={{ fontWeight: 'bold' }}>{role}:</span>{' '}
                        {/* Safely render user fields, rather than the entire object */}
                        {user && typeof user === 'object' ? (
                          <>
                            {user.username} ({user.email})
                          </>
                        ) : (
                          <span style={{ color: theme.palette.text.secondary }}>
                            Not Assigned
                          </span>
                        )}
                      </Typography>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No roles assigned.
                  </Typography>
                )}
              </Box>
            </Grid>

  
              {/* Threat Source */}
              <Grid item xs={12}>
                <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <Warning color="warning" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Threat Source
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {risk.threat_source} ({risk.threat_source_type || 'N/A'})
                  </Typography>
                </Box>
              </Grid>
  
              {/* Scenario */}
              <Grid item xs={12}>
                <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <RouteIcon color="error" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Scenario
                    </Typography>
                  </Box>
                  <Typography variant="body2">{risk.attack_scenario || 'N/A'}</Typography>
                </Box>
              </Grid>
  {/* Target Info */}
{risk.target_object && (
    <Grid item xs={12}>
  <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
      <ModeStandbyIcon color="primary" />
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Target Asset
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ mb: 1 }}>
      <strong>Title:</strong> {detailedRisks.target_object?.title || 'Not Specified'}
    </Typography>
    <Typography variant="body2" sx={{ mb: 1 }}>
      <strong>Type:</strong> {detailedRisks.target_object?.type || 'Unknown'}
    </Typography>
    <Typography variant="body2">
      <strong>Description:</strong> {detailedRisks.target_object?.description || 'No description available'}
    </Typography>
  </Box>
</Grid>
)}
        {/* Impact Instances Section */}
        <Grid item xs={12}>
          <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <FontAwesomeIcon icon={faExplosion} color="red"/>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Impact Instances
              </Typography>
            </Box>
            <ImpactInstances impacts={detailedRisks?.impact_instances} indexColors={indexColors} />
          </Box>
        </Grid>
              {/* Metrics Comparison */}
              <Grid item xs={12}>
  <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
      <Timeline color="primary" />
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Metrics Comparison
      </Typography>
    </Box>
    
    <Grid container spacing={2}>
      {/* Before Implementation */}
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            Before Measures Implementation
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {[
              { label: 'Likelihood', metric: detailedRisks.overall_likelihood },
              { label: 'Impact', metric: detailedRisks.impact },
              { label: 'Risk', metric: detailedRisks.risk },
            ].map(({ label, metric }) => (
              <Box
                key={label}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold', minWidth: '150px' }}
                >
                  {label}
                </Typography>
                {getMetricChip(metric, '')}
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>

      {/* After Implementation */}
      {detailedRisks.residual_risk && (
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
              After Measures Implementation
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {[
                { label: 'Residual Likelihood', metric: detailedRisks.residual_likelihood },
                { label: 'Residual Impact', metric: detailedRisks.residual_impact },
                { label: 'Residual Risk', metric: detailedRisks.residual_risk },
              ].map(({ label, metric }) => (
                <Box
                  key={label}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', minWidth: '150px' }}
                  >
                    {label}
                  </Typography>
                  {getMetricChip(metric, '')}
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  </Box>
</Grid>

  
              {/* Exploited Vulnerabilities */}
              {severityCounts && (
                <Grid item xs={12}>
                  <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <BugReport color="error" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Exploited Vulnerabilities
                      </Typography>
                    </Box>
                    {Object.keys(severityCounts).length > 0 ? (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {Object.entries(severityCounts).map(([severity, count]) => (
                          <Chip
                            key={severity}
                            icon={
                              <div
                                style={{
                                  ...circleStyle,
                                  backgroundColor:
                                    severity === 'High'
                                      ? theme.palette.error.main
                                      : severity === 'Medium'
                                      ? theme.palette.warning.main
                                      : severity === 'Low'
                                      ? theme.palette.success.main
                                      : theme.palette.grey[500],
                                }}
                              />
                            }
                            label={`${severity}: ${count}`}
                          />
                        ))}
                      </Box>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No exploited vulnerabilities found.
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}
<Grid item xs={12}>
  <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
      <HealthAndSafetyIcon color="success" /> {/* Security Icon */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Security Measures
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      {[
        { status: 'Not Started', color: theme.palette.warning.main, icon: <HourglassEmpty /> },
        { status: 'In Progress', color: theme.palette.info.main, icon: <Autorenew /> },
        { status: 'Completed', color: theme.palette.success.main, icon: <CheckCircle /> },
        { status: 'On Hold', color: theme.palette.error.main, icon: <PauseCircle /> },
        { status: 'Cancelled', color: theme.palette.grey[500], icon: <Cancel /> },
      ].map(({ status, color, icon }) => {
        const count = Array.isArray(detailedRisks.security_measures)
          ? detailedRisks.security_measures.filter(
              (measure) => measure.status === status
            ).length
          : 0;

        return (
          <Box
            key={status}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              padding: '6px 16px',
              borderRadius: '8px',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {React.cloneElement(icon, { sx: { color } })} {/* Color the icon */}
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {status}
              </Typography>
            </Box>
            <Typography variant="body2">({count})</Typography>
          </Box>
        );
      })}
    </Box>
  </Box>
</Grid>


            </Grid>
          </Box>
        );
        case 1: // Threat Events
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
              Attack Scenario
            </Typography>
            <ReadOnlyAttackScenario data={detailedRisks}
/>

          </Box>
        );
  
      case 2: // Vulnerabilities
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
              Vulnerabilities
            </Typography>
            <VulnerabilityTable vulns={detailedRisks?.all_vulnerabilities
} />
          </Box>
        );
        case 3: // Risk Response
  const response = detailedRisks?.responses?.[0]; // Assuming always one item in responses
  return (
<Box sx={{ p: 3 }}>
  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
    Risk Response
  </Typography>
  {response ? (
    <Grid container spacing={3}>
      {/* Created and Updated Timestamps */}
      <Grid item xs={12}>
        <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <InfoIcon color="primary" />
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Timestamps
            </Typography>
          </Box>
          <Typography variant="body2">
            <strong>Created At:</strong> {response.created_at || 'N/A'}
          </Typography>
          <Typography variant="body2">
            <strong>Updated At:</strong> {response.updated_at || 'N/A'}
          </Typography>
        </Box>
      </Grid>

      {/* Risk Response and Objective */}
      <Grid item xs={12}>
  <Box sx={{ p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 2 }}>
    {/* Header */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
      <ReplyIcon color="success" />
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Response Details
      </Typography>
    </Box>

    {/* Response Type */}
<Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
  <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: '120px' }}>
    Response Type:
  </Typography>
  <Chip
    icon={
      <Box
        sx={{
          ...circleStyle,
          backgroundColor: responseColors[
            risk?.responses?.[0]?.risk_response || 'unspecified'
          ],
        }}
      />
    }
    label={
      risk?.responses?.[0]?.risk_response
        ? risk.responses[0].risk_response.charAt(0).toUpperCase() + risk.responses[0].risk_response.slice(1)
        : 'Not Specified'
    }
    sx={{
      backgroundColor: theme.palette.grey[200], // Grey background for chip
      color: theme.palette.text.primary, // Text color
    }}
  />
</Box>

    {/* Objective */}
    <Box sx={{ mt: 1 }}>
      <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
        Objective:
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {response?.objective || 'Not Specified'}
      </Typography>
    </Box>
  </Box>
</Grid>


    </Grid>
  ) : (
    <Typography variant="body2" color="text.secondary">
      No risk response details available.
    </Typography>
  )}
</Box>

  );

      case 4: // Security Measures
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
              Security Measures
            </Typography>
            <SecurityMeasuresTable measures={detailedRisks?.security_measures} />
          </Box>
        );
  
      default:
        return null;
    }
  };

  return (
    <Box sx={{ height: '95%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Risk #{risk.id}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
  {(() => {
    const threatSource = risk.threat_source || "Unknown Source";
    const targetObject = risk.target_object?.title || "Unknown Target";
    const riskValue = risk.risk?.[0]?.value || "Unknown";
    const objective = risk.objective;

    return (
      <>
        {threatSource}
        {objective && (
          <>
            <Box sx={{ mx: 0.5 }}>
              <ArrowForward sx={{ fontSize: '1rem', verticalAlign: 'middle' }} />
            </Box>
            {objective}
          </>
        )}
        <Box sx={{ mx: 0.5 }}>
          <ArrowForward sx={{ fontSize: '1rem', verticalAlign: 'middle' }} />
        </Box>
        {targetObject}
      </>
    );
  })()}
</Typography>
      </Box>



      <Divider />
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Box sx={{ borderRight: 1, borderColor: 'divider', width: 200 }}>
        <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            variant="standard"
            sx={{
                width: '100%',
                '.MuiTabs-flexContainer': {
                alignItems: 'flex-start',
                },
                '.MuiTab-root': {
                justifyContent: 'flex-start',
                textAlign: 'left',
                paddingLeft: 2,
                },
                '.MuiTab-wrapper': {
                flexDirection: 'row',
                gap: '8px',
                },
            }}
            >
            <Tab icon={<InfoIcon />} iconPosition="start" label="Overview" />
            <Tab icon={<RouteIcon />} iconPosition="start" label="Attack Scenario" />
            <Tab icon={<BugReportIcon />} iconPosition="start" label="Vulnerabilities" />
            <Tab icon={<ReplyIcon />} iconPosition="start" label="Risk Response" />
            <Tab icon={<HealthAndSafetyIcon />} iconPosition="start" label="Security Measures" />
            </Tabs>

        </Box>
        <Box sx={{ flex: 1, overflowY: 'auto' }}>{renderTabContent()}</Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default RiskDetail;
