import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby'; // Example Icon

const circleStyle = {
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ImpactInstances = ({ impacts, indexColors }) => {
  const theme = useTheme();

  if (!Array.isArray(impacts) || impacts.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        No impacts found.
      </Typography>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {impacts.map((impact) => (
        <Box
          key={impact.id}
          sx={{
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {impact.title || 'Untitled Impact'}
          </Typography>
          <Typography variant="body2">
            {impact.description || 'No description available.'}
          </Typography>
          {impact.level && (
            <Chip
              label={`Level: ${impact.level}`}
              size="small"
              sx={{
                backgroundColor:
                  indexColors[impact.level] || theme.palette.grey[500],
                color: theme.palette.getContrastText(
                  indexColors[impact.level] || theme.palette.grey[500]
                ),
                mt: 1,
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ImpactInstances;
