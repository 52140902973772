import React, { useState,  useEffect} from 'react';
import {
  Box,
  Typography,
  Chip,
  Tooltip,
  Link,
  Drawer,
  IconButton,
  Grid,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import CloseIcon from '@mui/icons-material/Close';
import BugReportIcon from '@mui/icons-material/BugReport';
import SecurityIcon from '@mui/icons-material/Security';
import LockIcon from '@mui/icons-material/Lock';
import GavelIcon from '@mui/icons-material/Gavel';
import WarningIcon from '@mui/icons-material/Warning';
import {CircularProgress} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const getNatureIcon = (nature) => {
  switch (nature?.toLowerCase()) {
    case 'software vulnerability':
      return <BugReportIcon />;
    case 'license':
      return <GavelIcon />;
    case 'secret':
      return <LockIcon />;
    case 'misconfiguration':
      return <WarningIcon />;
    case 'noncompliance':
      return <SecurityIcon />;
    default:
      return null;
  }
};

const VulnerabilityTable = ({ vulns }) => {
  const [selectedVulnerability, setSelectedVulnerability] = useState(null);
  const theme = useTheme();
  const { getCompliance: getSoftwareVuln, compliance: softwareVuln } = useRequestCompliance({ endpoint: 'software_vulnerabilities' });
  const { getCompliance: getLicense, compliance: license, error: licenseError } = useRequestCompliance({ endpoint: 'licenses' });
  const { getCompliance: getSecret, compliance: secret } = useRequestCompliance({ endpoint: 'secrets' });
  const { getCompliance: getMisconfig, compliance: misconfig } = useRequestCompliance({ endpoint: 'misconfigurations' });


  useEffect(() => {
    if (selectedVulnerability) {
      switch (selectedVulnerability.nature?.toLowerCase()) {
        case 'software vulnerability':
          getSoftwareVuln(selectedVulnerability.software_vulnerability);
          break;
        case 'license':
          getLicense(selectedVulnerability.license);
          break;
        case 'secret':
          getSecret(selectedVulnerability.secret);
          break;
        case 'misconfiguration':
          getMisconfig(selectedVulnerability.misconfiguration);
          break;
        default:
          break;
      }
    }
  }, [selectedVulnerability, getSoftwareVuln, getLicense, getSecret, getMisconfig]);

  const statusColors = {
    open: theme.palette.error.main,
    closed: theme.palette.success.main,
    ignored: theme.palette.warning.main,
  };

  const severityColors = {
    'very high': theme.palette.riskLevels.veryHigh, // or pick a color
    critical: theme.palette.riskLevels.veryHigh,
    high: theme.palette.warning.main,
    medium: theme.palette?.riskLevels?.medium || theme.palette.info.main,
    'moderate': theme.palette?.riskLevels?.medium || theme.palette.info.main,
    low: theme.palette?.riskLevels?.low || theme.palette.info.light,
    info: theme.palette.info.main,
    'very low': theme.palette.riskLevels.veryLow,
  };
  

  const circleStyle = {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const renderDetails = () => {
    if (!selectedVulnerability) {
      return null;
    }
    switch (selectedVulnerability.nature?.toLowerCase()) {
      case 'software vulnerability':
        return softwareVuln ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Vulnerability ID:</Typography>
                <Typography variant="body1">{softwareVuln.vulnerability_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Package Name:</Typography>
                <Typography variant="body1">{softwareVuln.pkg_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>PURL:</Typography>
                <Typography variant="body1">{softwareVuln.purl}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>UID:</Typography>
                <Typography variant="body1">{softwareVuln.uid}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Installed Version:</Typography>
                <Typography variant="body1">{softwareVuln.installed_version}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Fixed Version:</Typography>
                <Typography variant="body1">{softwareVuln.fixed_version}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Status:</Typography>
                <Typography variant="body1">{softwareVuln.status}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Severity Source:</Typography>
                <Typography variant="body1">{softwareVuln.severity_source}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Primary URL:</Typography>
                <Link href={softwareVuln.primary_url} target="_blank" rel="noopener">{softwareVuln.primary_url}</Link>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Published Date:</Typography>
                <Typography variant="body1">{new Date(softwareVuln.published_date).toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Last Modified Date:</Typography>
                <Typography variant="body1">{new Date(softwareVuln.last_modified_date).toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>CWE IDs:</Typography>
                <Typography variant="body1">{softwareVuln.cwe_ids.join(', ')}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>References:</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {softwareVuln.references.map((ref, index) => (
                    <Link href={ref} key={index} target="_blank" rel="noopener" sx={{ wordBreak: 'break-word' }}>
                      {ref}
                    </Link>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : <CircularProgress />;
      case 'license':
        return license ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Package Name:</Typography>
                <Typography variant="body1">{license.pkg_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>License Name:</Typography>
                <Typography variant="body1">{license.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>File Path:</Typography>
                <Typography variant="body1">{license.file_path}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Confidence:</Typography>
                <Typography variant="body1">{license.confidence}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Link:</Typography>
                <Link href={license.link} target="_blank" rel="noopener">{license.link}</Link>
              </Grid>
            </Grid>
          </Box>
        ) : licenseError ? (
          <Typography variant="body1" color="error">Error loading license details: {licenseError.message}</Typography>
        ) : (
          <CircularProgress />
        );
      case 'secret':
        return secret ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Rule ID:</Typography>
                <Typography variant="body1">{secret.rule_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Category:</Typography>
                <Typography variant="body1">{secret.category}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Start Line:</Typography>
                <Typography variant="body1">{secret.start_line}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>End Line:</Typography>
                <Typography variant="body1">{secret.end_line}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Target:</Typography>
                <Typography variant="body1">{secret.target}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Match:</Typography>
                <Typography variant="body1">{secret.match}</Typography>
              </Grid>
            </Grid>
          </Box>
        ) : <CircularProgress />;
      case 'misconfiguration':
        return misconfig ? (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Type:</Typography>
                <Typography variant="body1">{misconfig.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>ID:</Typography>
                <Typography variant="body1">{misconfig.misconfig_id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Namespace:</Typography>
                <Typography variant="body1">{misconfig.namespace}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Query:</Typography>
                <Typography variant="body1">{misconfig.query}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Resolution:</Typography>
                <Typography variant="body1">{misconfig.resolution}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>Primary URL:</Typography>
                <Link href={misconfig.primary_url} target="_blank" rel="noopener">{misconfig.primary_url}</Link>
              </Grid>
            </Grid>
          </Box>
        ) : <CircularProgress />;
      default:
        return null;
    }
  };

  const handleRowClick = (vulnerability) => {
    setSelectedVulnerability(vulnerability);
  };

  const closeDrawer = () => {
    setSelectedVulnerability(null);
  };

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ cell, row }) => (
        <Tooltip title={cell.getValue()} arrow>
          <Link
            component="button"
            variant="body2"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => handleRowClick(row.original)}
          >
            {getNatureIcon(row.original.nature)}
            {truncateText(cell.getValue(), 20)}
          </Link>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span>{truncateText(cell.getValue(), 50)}</span>
        </Tooltip>
      ),
    },
    { accessorKey: 'nature', header: 'Nature' },
    {
      accessorKey: 'severity',
      header: 'Severity',
      Cell: ({ cell }) => {
        const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
        const color = severityColors[value] || theme.palette.grey[500];
        return (
          <Chip
            icon={<div style={{ ...circleStyle, backgroundColor: color }} />}
            label={cell.getValue()}
          />
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => {
        const value = cell.getValue() ? cell.getValue().toLowerCase() : '';
        const color = statusColors[value] || theme.palette.grey[500];
        return (
          <Chip
            icon={<div style={{ ...circleStyle, backgroundColor: color }} />}
            label={cell.getValue()}
          />
        );
      },
    },
  ];

  return (
    <>
        <MaterialReactTable
          columns={columns}
          data={vulns}
          enableColumnOrdering
          enableStickyHeader
          enableGrouping
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
              borderRadius: '0',
              border: 1,
              borderColor: 'grey.200',
            },
          }}
        />
      {selectedVulnerability && (
    <Drawer
    anchor="right"
    open={Boolean(selectedVulnerability)}
    onClose={closeDrawer}
    sx={{
      display: { xs: 'block', sm: 'block', md: 'block' },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: '60%',
        marginTop: "64px",
      },
    }}
  >
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Vulnerability Details</Typography>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Title</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{selectedVulnerability?.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Description</Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', mb: 2 }}>{selectedVulnerability?.description}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Nature</Typography>
            <Typography variant="body1">{selectedVulnerability?.nature}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Severity</Typography>
            <Chip
              label={selectedVulnerability?.severity}
              sx={{
                backgroundColor: selectedVulnerability?.severity ? severityColors[selectedVulnerability.severity.toLowerCase()] : theme.palette.grey[500],
                color: theme.palette.getContrastText(selectedVulnerability?.severity ? severityColors[selectedVulnerability.severity.toLowerCase()] : theme.palette.grey[500]),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>Status</Typography>
            <Chip
              label={selectedVulnerability?.status}
              sx={{
                backgroundColor: selectedVulnerability?.status ? statusColors[selectedVulnerability.status.toLowerCase()] : theme.palette.grey[500],
                color: theme.palette.getContrastText(selectedVulnerability?.status ? statusColors[selectedVulnerability.status.toLowerCase()] : theme.palette.grey[500]),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {selectedVulnerability && renderDetails()}
    </Box>
  </Drawer>
      )}
    </>
  );
};

export default VulnerabilityTable;
