import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Tabs,
    Tab,
    Box,
    Drawer,
} from '@mui/material';
import RiskHeatmap from 'src/components/Risk/Metrics/RiskHeatmap';
import RiskLevels from 'src/components/Risk/Metrics/RiskLevels';
import StakeHoldermetric from 'src/components/Risk/Metrics/StakeHoldermetric';
import CardWrapper from 'src/components/BaseLayout/CardWrapper';
import VulnerabilityMetrics from 'src/components/Risk/Metrics/VulnerabilityMetrics';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import { useParams } from 'react-router-dom';
import TrendMetrics from 'src/components/Risk/Metrics/TrendMetrics';
import CustomFilter from 'src/components/General/CustomFilter';
import RiskTable from 'src/components/Risk/RiskTable';
import RiskDetail from 'src/components/Risk/RiskDetail';
import VulnerabilityTable from 'src/components/Risk/VulnerabilityTable';
import SecurityMeasuresTable from 'src/components/Risk/SecurityMeasuresTable';

export default function MonitorPage() {
    const { getComplianceList: getOrganizations, complianceList: organizations } = useRequestCompliance({ endpoint: 'organizations', resourceLabel: 'Organizations' });
    const { getComplianceList: getMissions, complianceList: missions } = useRequestCompliance({ endpoint: 'missions', resourceLabel: 'Missions' });
    const { getComplianceList: getInfoSystems, complianceList: informationSystems } = useRequestCompliance({ endpoint: 'information-systems', resourceLabel: 'Information systems' });

    const { getComplianceList: getRiskMetric, complianceList: riskMetrics } = useRequestCompliance({ endpoint: 'riskinstancemetrics', resourceLabel: 'Risk Instance metrics' });
    const { getComplianceList: getVulnMetric, complianceList: vulnMetrics } = useRequestCompliance({ endpoint: 'vulnerabilityinstancemetrics', resourceLabel: 'Vulnerability metrics' });
    const { getComplianceList: getSHMetric, complianceList: shMetrics } = useRequestCompliance({ endpoint: 'stakeholders/average_threat_level', resourceLabel: 'Stakeholder metrics' });
    const { getComplianceList: getRisks, complianceList: risks } = useRequestCompliance({ endpoint: 'risk_instances', resourceLabel: 'Risks' });
    const { getComplianceList: getVulns, complianceList: vulns } = useRequestCompliance({
        endpoint: 'vulnerabilityinstances',
    });
    const { getComplianceList: getSecMeasures, complianceList: secMeasures } = useRequestCompliance({
        endpoint: 'securitymeasures',
    });
    const { id } = useParams();
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [missionOptions, setMissionOptions] = useState([]);
    const [informationSystemOptions, setInformationSystemOptions] = useState([]);

    const [selectedFilters, setSelectedFilters] = useState({
        organization: null,
        mission: [],
        informationSystem: [],
    });

    const [activeTab, setActiveTab] = useState(0);
    const [selectedRisk, setSelectedRisk] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const handleSelectionChange = (selections) => {
        const filter = {};
        setSelectedFilters(prev => ({
            ...prev,
            ...selections
        }));
        if (selections.organization) {
            filter['organization'] = selections.organization.value;
        }
        if (selections.mission?.length > 0) {
            const missionIds = selections.mission.map(option => option.value).join(',');
            filter['missions'] = missionIds;
        }
        if (selections.informationSystem?.length > 0) {
            const systemIds = selections.informationSystem.map(option => option.value).join(',');
            filter['information_systems'] = systemIds;
        }
        getRiskMetric(filter);
        getVulnMetric(filter);
        getSHMetric(filter);
        getRisks(filter);
        getVulns(filter);
        getSecMeasures(filter);
    };

    useEffect(() => {
        if (selectedFilters.organization) {
            getMissions({ organization: selectedFilters.organization.value });
        }
    }, [selectedFilters.organization, getMissions]);

    useEffect(() => {
        if (missions.results) {
            const formattedOptions = missions.results.map(mission => ({ value: mission.id, label: mission.title }));
            setMissionOptions(formattedOptions);
        }
    }, [missions]);

    useEffect(() => {
        getOrganizations();
    }, [getOrganizations]);

    useEffect(() => {
        if (organizations.results) {
            const formattedOptions = organizations.results.map(org => ({ value: org.id, label: org.title }));
            setOrganizationOptions(formattedOptions);
        }
    }, [organizations]);

    useEffect(() => {
        const filter = {};
        if (selectedFilters.organization) {
            filter.organization = selectedFilters.organization.value;
        }
        if (selectedFilters.mission?.length > 0) {
            filter.missions = selectedFilters.mission.map(m => m.value).join(',');
        }
        getInfoSystems(filter);
    }, [selectedFilters.organization, selectedFilters.mission, getInfoSystems]);

    useEffect(() => {
        if (informationSystems.results) {
            const formattedOptions = informationSystems.results.map(sys => ({ value: sys.id, label: sys.title }));
            setInformationSystemOptions(formattedOptions);
        }
    }, [informationSystems]);

    useEffect(() => {
        let filter = '';
        if (id) {
            filter = { risk_assessment_instance: id };
            getRiskMetric(filter);
            getVulnMetric(filter);
            getSHMetric(filter);
        }
    }, [id, getRiskMetric, getVulnMetric, getSHMetric]);

    console.log(secMeasures)


    const isStakeholderMetricsEmpty = shMetrics?.message;

    const handleRiskRowClick = (risk) => {
        setSelectedRisk(risk);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedRisk(null);
    };

    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Risk Monitoring
                </Typography>
            </Grid>
            {!id && (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <CustomFilter
                                organizationOptions={organizationOptions}
                                missionOptions={missionOptions}
                                informationSystemOptions={informationSystemOptions}
                                onSelectionChange={handleSelectionChange}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )}
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Overview" />
                        <Tab label="Risks" />
                        <Tab label="Vulnerabilities" />
                        <Tab label="Security measures" />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ padding: 2 }} >
                    {activeTab === 0 && ( <>
                        <Grid justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Risks
                            </Typography>
                        </Grid>
                        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 0 }}>
                            <Grid item xs={2} md={2}>
                                <CardWrapper title="Risk Levels" subheader="Overview of current risk levels">
                                    <RiskLevels data={riskMetrics?.results?.risk_level_counts || {}} />
                                </CardWrapper>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CardWrapper title="Residual Risk Levels" subheader="Overview of residuel risk levels">
                                    <RiskLevels data={riskMetrics?.results?.residual_risk_level_counts || {}} />
                                </CardWrapper>
                            </Grid>
                            <Grid item xs={8} md={8}>
                                <CardWrapper title="Risk Instances status" subheader="Monthly Opened and closed risk instances">
                                    <TrendMetrics data={riskMetrics?.results?.risks || {}} />
                                </CardWrapper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
                            <Grid item xs>
                                <CardWrapper title="Current Risk Heatmap" subheader="Visualization of current impacts and likelihood">
                                    <RiskHeatmap data={riskMetrics?.results?.impact_likelihood || {}} />
                                </CardWrapper>
                            </Grid>
                            <Grid item xs>
                                <CardWrapper title="Residual Risk Heatmap" subheader="Visualization of residual impacts and likelihood">
                                    <RiskHeatmap data={riskMetrics?.results?.residual_impact_likelihood || {}} />
                                </CardWrapper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
                            <Grid item xs={6} md={2}>
                                <CardWrapper title="Risk responses by type" subheader="Overview of all the taken reponses by type">
                                    <RiskLevels data={riskMetrics?.results?.threat_response_counts || {}} />
                                </CardWrapper>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <CardWrapper title="Risk measures by status" subheader="Overview of all the identified measures">
                                    <RiskLevels data={riskMetrics?.results?.security_measure_counts_by_status || {}} />
                                </CardWrapper>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <CardWrapper title="Risk measures Trend" subheader="Monthly risk measures created vs completed">
                                    <TrendMetrics data={riskMetrics?.results?.measures || {}} />
                                </CardWrapper>
                            </Grid>
                        </Grid>
                        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                            Vulnerabilities
                        </Typography>
                        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ marginTop: 0 }}>
                            <Grid item xs>
                                <CardWrapper title="Vulnerabilities" subheader="Metrics specific to Vulnerabilities">
                                    <VulnerabilityMetrics data={vulnMetrics?.results} />
                                </CardWrapper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ marginTop: 2 }}>
                            <Grid item xs>
                                <CardWrapper title="Vulnerabilities Discoveries vs Resolutions" subheader="Monthly Vulnerabilities discoveries and resolutions">
                                    <TrendMetrics data={vulnMetrics?.results} />
                                </CardWrapper>
                            </Grid>
                        </Grid>
                        {!isStakeholderMetricsEmpty && (
                            <>
                                <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                                    Stakeholder Threats
                                </Typography>
                                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ marginTop: 0 }}>
                                    <Grid item xs={12} md={6}>
                                        <CardWrapper title="Current Stakeholder Threat" subheader="Metrics specific to stakeholders current threat to the object scope">
                                            <StakeHoldermetric data={shMetrics?.results?.average_overall_threat_levels || {}} />
                                        </CardWrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CardWrapper title="Residual Stakeholder Threat" subheader="Metrics specific to stakeholders residual threat to the object scope">
                                            <StakeHoldermetric data={shMetrics?.results?.average_residual_overall_threat_levels || {}} />
                                        </CardWrapper>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
                                    <Grid item xs={6} md={2}>
                                        <CardWrapper title="Stakeholders responses by type" subheader="Overview of all the taken reponses by type">
                                            <RiskLevels data={shMetrics?.results?.threat_response_counts || {}} />
                                        </CardWrapper>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <CardWrapper title="Threat measures by status" subheader="Overview of all the identified measures">
                                            <RiskLevels data={shMetrics?.results?.security_measure_counts_by_status || {}} />
                                        </CardWrapper>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <CardWrapper title="Risk measures Trend" subheader="Monthly threat measures created vs closed">
                                            <TrendMetrics data={shMetrics?.results?.measures || {}} />
                                        </CardWrapper>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                    )}
                    {activeTab === 1 && (
                        <RiskTable risks={risks.results || []} onRiskRowClick={handleRiskRowClick} />
                    )}
                    {activeTab === 2 && (
                        <VulnerabilityTable vulns={vulns?.results} />
                    )}
                    {activeTab === 3 && (
                        <SecurityMeasuresTable measures={secMeasures?.results} />
                    )}
                </Box>
            </Grid>

            {/* Drawer for Risk Details */}
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                PaperProps={{ sx: { width: "80%", padding: 2, marginTop: '64px' } }}
            >
                <RiskDetail risk={selectedRisk} />
            </Drawer>
        </Grid>
    );
}
