import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import Autorenew from '@mui/icons-material/Autorenew';
import CheckCircle from '@mui/icons-material/CheckCircle';
import PauseCircle from '@mui/icons-material/PauseCircle';
import Cancel from '@mui/icons-material/Cancel';

const SecurityMeasuresTable = ({ measures }) => {
  const statusConfig = {
    'not started': { color: '#FF9800', icon: <HourglassEmpty /> }, // Amber for "Not Started"
    'in progress': { color: '#2196F3', icon: <Autorenew /> }, // Blue for "In Progress"
    completed: { color: '#4CAF50', icon: <CheckCircle /> }, // Green for "Completed"
    'on hold': { color: '#F44336', icon: <PauseCircle /> }, // Red for "On Hold"
    cancelled: { color: '#9E9E9E', icon: <Cancel /> }, // Grey for "Cancelled"
  };

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      size: 200,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span>{cell.getValue()}</span>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 300,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue() || 'No description provided'} arrow>
          <span>{cell.getValue() || 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 150,
      Cell: ({ cell }) => {
        const value = cell.getValue()?.toLowerCase() || 'unknown';
        const config = statusConfig[value] || { color: '#9E9E9E', icon: null };

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {config.icon &&
              React.cloneElement(config.icon, {
                sx: { color: config.color },
              })
            }
            <Typography variant="body2">{cell.getValue() || 'Unknown'}</Typography>
          </Box>
        );        
      },
    },
    {
      accessorKey: 'complexity_display',
      header: 'Complexity',
      size: 100,
      Cell: ({ cell }) => <span>{cell.getValue() || 'N/A'}</span>,
    },
    {
      accessorKey: 'created_by',
      header: 'Created By',
      size: 200,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span>{cell.getValue() || 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      size: 150,
      Cell: ({ cell }) => (
        <span>{cell.getValue() ? cell.getValue() : 'N/A'}</span>
      ),
    },
    {
      accessorKey: 'completed_at',
      header: 'Completed At',
      size: 150,
      Cell: ({ cell }) => (
        <span>{cell.getValue() ? new Date(cell.getValue()).toLocaleString() : 'N/A'}</span>
      ),
    },
  ];

  return (
      <MaterialReactTable
        columns={columns}
        data={measures}
        enableColumnOrdering
        enableStickyHeader
        enableGrouping
        muiTableContainerProps={{
          sx: { maxHeight: '600px' },
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: 1,
            borderColor: 'grey.200',
          },
        }}
      />
  );
};

export default SecurityMeasuresTable;
