import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable
} from 'material-react-table';
import {
  Stack, Button, Box, Typography, IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyValueFilter from './KeyValueFilter';

const MRTcustomized = ({
  results = [],
  initialSelectedIds = [],
  enableRowActions,
  enableRowSelection = true,
  renderRowActions,
  onSelectedIdsChange,
  enableMultiRowSelection,
  firstColumnAccessor,
  columnOrder,
  filterBycolumnOrder = false,
  renderRowActionMenuItems,
  enableDownload = false, 
  nestedObjectName = 'threat_event_instances', 
  onIconClick,
  extraFields,
  onEditClick,
  renderMenuItems
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const isInitialSelectionSet = useRef(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState(null);

  // State for tag filtering
  const [tagFilters, setTagFilters] = useState([]);
  const [filterOperators, setFilterOperators] = useState([]);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveRow(null);
  };

  console.log(extraFields)

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
  });

  const handleExportRows = (rows) => {
    const flattenObject = (obj) => {
      const flatten = {};
      const recurse = (cur, prop) => {
        if (Object(cur) !== cur || Array.isArray(cur)) {
          flatten[prop] = cur;
        } else {
          let isEmpty = true;
          for (const [key, value] of Object.entries(cur)) {
            isEmpty = false;
            recurse(value, prop ? `${prop}.${key}` : key);
          }
          if (isEmpty && prop) {
            flatten[prop] = null;
          }
        }
      };
      recurse(obj, '');
      return flatten;
    };
    const rowData = rows.map((row) => flattenObject(row.original));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const flattenedData = useMemo(() => results?.map(item => {
    const newItem = { ...item };  
    if (item.roles) {
      Object.keys(item.roles).forEach(roleKey => {
        newItem[roleKey] = item.roles[roleKey]?.username;
      });
      delete newItem.roles;
    } 
    
    extraFields?.forEach(field => {
      console.log(item[field])   
      if (typeof item[field] === 'object' && item[field] !== null && !Array.isArray(item[field])) {
        Object.assign(newItem, item[field]);
        delete newItem[field];
      }
    });
    return newItem;
  }), [results, extraFields]);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(flattenedData);
    download(csvConfig)(csv);
  };

  useEffect(() => {
    if (!isInitialSelectionSet.current) {
      const initialRowSelectionState = Array.isArray(initialSelectedIds)
        ? initialSelectedIds.reduce((acc, currId) => {
          acc[currId] = true;
          return acc;
        }, {})
        : { [initialSelectedIds]: true };

      setRowSelection(initialRowSelectionState);
      isInitialSelectionSet.current = true;
    }
  }, [initialSelectedIds]);

  useEffect(() => {
    const selectedIdsArray = Object.entries(rowSelection)
      .filter(([key, value]) => value)
      .map(([key]) => Number(key));

    if (enableRowSelection && onSelectedIdsChange) {
      if (enableMultiRowSelection === false) {
        onSelectedIdsChange(selectedIdsArray[0]);
      } else {
        onSelectedIdsChange(selectedIdsArray);
      }
    }
  }, [rowSelection, enableMultiRowSelection, enableRowSelection, onSelectedIdsChange]);

  // Filtering logic for tag filters
  const filteredData = useMemo(() => {
    const activeFilters = tagFilters.filter(({ key, value }) => key && value);
    if (activeFilters.length === 0) {
      return flattenedData; // No filters applied, return all data
    }

    return flattenedData.filter((row) => {
      let result = null;

      activeFilters.forEach(({ key, value }, index) => {
        if (!key || !value) return;

        const matches = row.tags?.some((tag) => tag.key === key && tag.value.toLowerCase().includes(value.toLowerCase()));

        if (result === null) {
          result = matches;
        } else if (filterOperators[index - 1] === 'AND') {
          result = result && matches;
        } else if (filterOperators[index - 1] === 'OR') {
          result = result || matches;
        }
      });

      return result;
    });
  }, [flattenedData, tagFilters, filterOperators]);

  const columns = useMemo(() => {
    if (!Array.isArray(flattenedData) || flattenedData.length === 0) return [];
    const sample = flattenedData[0];

    const columnList = Object.keys(sample).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1),
    }));

    if (firstColumnAccessor) {
      const firstColumnIndex = columnList.findIndex(
        (column) => column.accessorKey === firstColumnAccessor
      );
      if (firstColumnIndex !== -1) {
        const firstColumn = columnList.splice(firstColumnIndex, 1);
        columnList.unshift(...firstColumn);
      }
    }

  // Exclude columns with nested data (for example, objects or arrays) and columns with '_id' in the key
  const filteredColumns = columnList.filter((column) => {
    const value = sample[column.accessorKey];
    return !Array.isArray(value) && typeof value !== 'object' && !column.accessorKey.includes('_id');
  });

    // Add Tags Column
    filteredColumns.push({
      accessorKey: 'tags',
      header: 'Tags',
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <Box>
          {cell.getValue()?.map((tag, index) => (
            <span key={index} style={{ marginRight: '8px', backgroundColor: '#f0f0f0', padding: '4px', borderRadius: '4px' }}>
              {tag.key}: {tag.value}
            </span>
          ))}
        </Box>
      ),
    });

    return filteredColumns;
  }, [flattenedData, firstColumnAccessor]);

  const tableColumns = useMemo(() => {
    if (!Array.isArray(flattenedData) || flattenedData.length === 0) return [];

    if (columnOrder && filterBycolumnOrder && columnOrder.length > 0) {
      const columnKeysSet = new Set(columnOrder);

      const filteredColumns = columns.filter((column) =>
        columnKeysSet.has(column.accessorKey)
      );

      return filteredColumns;
    } else {
      return columns;
    }
  }, [flattenedData, columns, columnOrder, filterBycolumnOrder]);

  const renderDownloadButtons = () => {
    if (!enableDownload) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap' }}>
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button disabled={table.getPrePaginationRowModel().rows.length === 0} onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)} startIcon={<FileDownloadIcon />}>
          Export All Rows
        </Button>
        <Button disabled={table.getRowModel().rows.length === 0} onClick={() => handleExportRows(table.getRowModel().rows)} startIcon={<FileDownloadIcon />}>
          Export Page Rows
        </Button>
        <Button disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()} onClick={() => handleExportRows(table.getSelectedRowModel().rows)} startIcon={<FileDownloadIcon />}>
          Export Selected Rows
        </Button>
      </Box>
    );
  };

  const renderDetailPanel = useCallback(({ row }) => {
    const nestedObject = row?.original[nestedObjectName];
    if (!nestedObject || nestedObject.length === 0) {
      return null;
    }
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {nestedObject.map((instance, index) => (
              <TableRow key={index}>
                {renderMenuItems && (
                  <TableCell>{renderMenuItems(instance, handleMenuClose)}</TableCell>
                )}
                {onIconClick && (
                  <TableCell>
                    <IconButton aria-label="delete" size="small" onClick={() => onIconClick(instance.id, row)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                )}
                {onEditClick && (
                  <TableCell>
                    <IconButton aria-label="edit" size="small" onClick={() => onEditClick(instance, row)}>
                      <EditIcon color="warning" />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell><Typography>{instance.title}</Typography></TableCell>
                <TableCell><Typography>{instance.description}</Typography></TableCell>
                <TableCell><Typography>{instance.objective}</Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [nestedObjectName, onIconClick, onEditClick]);

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: filteredData,
    enablePagination: true,
    getRowId: (row) => row.id,
    enableGrouping: true,
    initialState: {
      columnOrder,
      columnVisibility: {
        id: false,
        created_by: false,
        created_at: false,
        updated_at: false
      }
    },
    enableRowSelection,
    enableStickyHeader: true,
    enableFullScreenToggle: false,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px'
      }
    },
    defaultColumn: {
      maxSize: 400,
      minSize: 20,
      size: 300
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowActions,
    renderRowActions,
    enableMultiRowSelection,
    renderRowActionMenuItems,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {renderDownloadButtons()}
        {/* KeyValueFilter Component */}
        <KeyValueFilter
          tagFilters={tagFilters}
          filterOperators={filterOperators}
          uniqueTagKeys={results.reduce((acc, row) => {
            row.tags?.forEach((tag) => {
              if (!acc.includes(tag.key)) acc.push(tag.key);
            });
            return acc;
          }, [])}
          handleTagFilterChange={(index, field, value) => {
            const newFilters = tagFilters.map((filter, i) =>
              i === index ? { ...filter, [field]: value } : filter
            );
            setTagFilters(newFilters);
          }}
          handleOperatorChange={(index, value) => {
            const newOperators = [...filterOperators];
            newOperators[index] = value;
            setFilterOperators(newOperators);
          }}
          handleAddTagFilter={() => {
            setTagFilters([...tagFilters, { key: '', value: '' }]);
            setFilterOperators([...filterOperators, 'AND']);
          }}
          handleRemoveTagFilter={(index) => {
            const newFilters = tagFilters.filter((_, i) => i !== index);
            const newOperators = filterOperators.filter((_, i) => i !== index);
            setTagFilters(newFilters);
            setFilterOperators(newOperators);
          }}
        />
      </Box>
    ),
    renderDetailPanel
  });

  return (
    <Stack sx={{ m: '2rem 0' }}>
      <MaterialReactTable table={table} />
    </Stack>
  );
};

export default MRTcustomized;
