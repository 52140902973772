import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode = ({ data, type, icon: Icon, iconColor = '#555', backgroundColor = '#f0f0f0' }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const isThreatNode = type === 'threat' || type === 'threatSource'; // Include threatSource
  console.log(data)
  return (
    <div
      className="node-container"
      style={{ position: 'relative' }}
      onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on hover
      onMouseLeave={() => setTooltipVisible(false)} // Hide tooltip on leave
    >
      {isTooltipVisible && (
        <div
          style={{
            position: 'absolute',
            top: '80px', // Place tooltip above the node
            left: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '300px', // Maximum width of tooltip
            backgroundColor: '#333',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '12px',
            wordWrap: 'break-word', // Break long words
            overflowWrap: 'break-word', // Handle overflow gracefully
            zIndex: 10,
            whiteSpace: 'normal', // Allow text wrapping
          }}
        >
          {data.description}
        </div>
      )}

      <div
        className="custom-node"
        style={{
          backgroundColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: isThreatNode ? '50%' : '8px', // Circle for threat nodes
          width: isThreatNode ? '80px' : 'auto', // Set fixed width for circles
          height: isThreatNode ? '80px' : 'auto', // Set fixed height for circles
          padding: isThreatNode ? '0' : '10px', // Adjust padding for circles
        }}
      >
        <div className="node-icon">
          <Icon style={{ fontSize: 30, color: iconColor }} />
        </div>

        {/* Handles for connecting (source and target attack) */}
        {isThreatNode && (
          <>
            <Handle
              type="source"
              position={Position.Right}
              id="source-threat"
              style={{ backgroundColor: '#b71c1c' }}
            />
            <Handle
              type="target"
              position={Position.Left}
              id="target-threat"
              style={{ backgroundColor: '#b71c1c' }}
            />
            <Handle
              type="source"
              position={Position.Top}
              id="source-attack"
              style={{ backgroundColor: '#b71c1c' }}
            />
          </>
        )}
        {!isThreatNode && (
          <>
            {/* Handles for display only (not connectable) */}
            <Handle
              type="target"
              position={Position.Left}
              id="target-attack"
              style={{ backgroundColor: '#b71c1c' }}
            />
            <Handle
              type="target"
              position={Position.Top}
              id="top-handle"
              isConnectable={false} // This handle is not connectable
              className={'invisible-handle'}
            />
            <Handle
              type="source"
              position={Position.Bottom}
              id="bottom-handle"
              isConnectable={false} // This handle is not connectable
              className={'invisible-handle'}
            />
          </>
        )}
      </div>
      <span
        style={{
          marginTop: '8px',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        {data.label}
      </span>
    </div>
  );
};

export default CustomNode;
